<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="page-container-card primary--border" outlined>
                    <v-card-title class="title">
                        News
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="notice-read"
                                        @action="form.dialog = true,$refs.form ? $refs.form.reset(): ''">
                                &nbsp; New News
                            </add-button>
                        </transition>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            sort-by="id"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left">{{ item.excerpt }}</td>
                                <td class="text-xs-left">
                                    <span v-if="item.status === 'active'"><v-icon
                                            color="green">check_circle</v-icon></span>
                                    <span v-if="item.status === 'inactive'"><v-icon color="red">cancel</v-icon></span>
                                </td>
                                <td class="text-xs-left"><a :href="item.cover" target="_blank">{{item.cover ?
                                    'File':''}}</a></td>
                                <td class="text-xs-left">{{ item.creator }}</td>

                                <td class="text-xs-right">
                                    <edit-button permission="notice-read"
                                                 @agree="form.edit(item), form.cover = ''"/>
                                    <delete-button permission="notice-read" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" fullscreen hide-overlay>
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="form.dialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="validate">Save</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container fluid grid-list-md relative>
                                <v-layout row wrap>
                                    <v-flex sm8>
                                        <v-text-field outlined dense autocomplete="off" label="Title*"
                                                      required class="pa-0 pt-3"
                                                      v-model="form.title"
                                                      name="title" :error-messages="form.errors.get('title')"
                                                      :rules="[v => !!v || 'Title Field is required']"/>

                                        <v-select outlined dense :items="statuses" class="pa-0 pt-3" label="Status"
                                                  name="status" v-model="form.status"
                                                  :error-messages="form.errors.get('status')"
                                                  :rules="[v => !!v || 'Status Field is required']"/>

                                        <v-textarea outlined dense autocomplete="off" label="Excerpt*"
                                                    required class="pa-0 pt-3"
                                                    v-model="form.excerpt"
                                                    name="title" :error-messages="form.errors.get('excerpt')"
                                                    :rules="[v => !!v || 'Excerpt Field is required']"/>
                                    </v-flex>
                                    <v-flex sm4>
                                        <v-card color="primary" class="mt-3"
                                                style="overflow: hidden; min-height: 200px; background: #fcfcfc">
                                            <span v-if="imagePicker.image"
                                                  style="position: absolute; right: 0; top: 10px; z-index: 2"><v-btn
                                                    text small color="error" @click="removeImage"><v-icon
                                                    small>close</v-icon></v-btn></span>
                                            <v-img v-if="imagePicker.image" :src="imagePicker.image"
                                                   class="white--text align-end"
                                                   gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"></v-img>
                                        </v-card>
                                        <image-picker class="mt-3" label="Select Cover Image" ref="picker" :ratio="16/9"
                                                      @result="onPickerResult"></image-picker>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <wysiwyg v-model="form.description"/>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                description: '',
                status: '',
                excerpt: '',
                cover: '',
            }, '/api/news'),
            search: null,
            imagePicker: {
                name: null,
                image: null,
                src: null,
                cropped: {}
            },
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {},
            statuses: [{value: 'active', text: 'Active'}, {value: 'inactive', text: 'Inactive'}],
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Title', align: 'left', value: 'title', sortable: false},
                {text: 'Excerpt', align: 'left', value: 'excerpt', sortable: false},
                {text: 'Status', align: 'left', value: 'status', sortable: false},
                {text: 'File', align: 'left', value: 'file', sortable: false},
                {text: 'Creator', align: 'left', value: 'creator', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 120}
            ],
        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            }
        },

        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.cover = this.imagePicker.cropped;
                this.form.store();
            },
            /* handleFileUpload() {
                this.form.cover = this.$refs.file.files[0];
            }, */

            onPickerResult(data) {
                this.imagePicker = data;
                this.$refs.picker.reset();
            },

            removeImage() {
                this.imagePicker = {
                    name: null,
                    image: null,
                    src: null,
                    cropped: {}
                };
            }

        }
    }
</script>
<style lang="scss" scoped>
    .public {
        background: red;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }

    .private {
        background: #fb8c00 !important;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }

    .both {
        background: green;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
</style>